import { lpColors } from "../constants/themeColors.constant";

export const partnerDefaultStyle = {
  legacyProgressBar: "#fe6018",
  legacyInputFocusBg: "#fe6018",
  legacyInputHoverBg: "#fe6018",
  backgroundColors: lpColors,
  typographyColors: lpColors,
  borders: {
    ctaBtnRadius: "0.4rem",
    ctaPrimary: "none",
    ctaSecondary: "0.1rem solid #DEE2E6",
    ctaPhone: "0.3rem solid #0eb290"
  },
  boxShadows: { ctaBtn: "0 1px 2px 0 rgba(33, 37, 41, 0.1)" },
  fontWeights: {
    ctaPrimary: "500",
    ctaSecondary: "500",
    ctaTertiary: "500",
    ctaPhone: "500"
  },
  buttonColors: {
    ctaPrimary: {
      text: {
        regular: lpColors.white,
        hover: lpColors.white,
        focus: lpColors.white
      },
      background: {
        regular: lpColors.primary500,
        hover: lpColors.primary600,
        focus: lpColors.primary700
      }
    },
    ctaSecondary: {
      text: {
        regular: lpColors.grey800,
        hover: lpColors.grey800,
        focus: lpColors.grey800
      },
      background: {
        regular: lpColors.white,
        hover: lpColors.grey50,
        focus: lpColors.grey150
      }
    },
    ctaTertiary: {
      text: {
        regular: lpColors.primary500,
        hover: lpColors.primary600,
        focus: lpColors.primary500
      }
    },
    ctaPhone: {
      text: {
        regular: lpColors.white,
        hover: lpColors.white,
        focus: lpColors.white
      },
      background: {
        regular: lpColors.success600,
        hover: lpColors.success600,
        focus: lpColors.success600
      }
    }
  }
};
