















import { Vue } from "vue-property-decorator";
import { AmplifyEventBus } from "aws-amplify-vue";

function findLocalStorageItem(substring: string) {
  for (const i in localStorage) {
    if (i.includes(substring)) {
      return localStorage.getItem(i);
    }
  }
  return null;
}

export default Vue.extend({
  name: "Header",
  data() {
    return {
      signedIn: false
    };
  },
  async beforeCreate() {
    try {
      //@ts-ignore
      await this.$Amplify.Auth.currentAuthenticatedUser();

      const item = findLocalStorageItem("idToken");
      document.cookie = `tokenCognito=${item}`;

      //@ts-ignore
      this.signedIn = true;
    } catch (err) {
      //@ts-ignore
      this.signedIn = false;
    }
    AmplifyEventBus.$on("authState", (info: string) => {
      if (info === "signedIn") {
        const item = findLocalStorageItem("idToken");
        document.cookie = `tokenCognito=${item}`;
      }

      //@ts-ignore
      this.signedIn = info === "signedIn";
    });
  }
});
