export const lpColors = {
  black: "#000000",
  white: "#FFFFFF",
  highlightGreen: "#4FFFB5",
  violet: "#EAEAF4",
  legacyBgGrey: "#f7f7f7",
  grey50: "#FBFCFD",
  grey100: "#F6F9FC",
  grey125: "#f0f3f7",
  grey150: "#EAEEF1",
  grey200: "#DEE2E6",
  grey300: "#BDC4CB",
  grey400: "#868E96",
  grey500: "#6b757f",
  grey600: "#495057",
  grey700: "#212529",
  grey800: "#020202",
  primary100: "#D6F0FF",
  primary200: "#ADDEFF",
  primary300: "#84C9FF",
  primary400: "#66B4FF",
  primary500: "#3392FF",
  primary600: "#2571DB",
  primary700: "#1954B7",
  primary800: "#103B93",
  primary900: "#051259",
  primary1000: "#2B3259",
  danger100: "#fddcd0",
  danger300: "#FEE7E7",
  danger500: "#E0182D",
  success100: "#0A9585",
  success200: "#cefcdc",
  success300: "#6EF0AF",
  success500: "#14cf96",
  success600: "#0eb290",
  secondary100: "#ffe5e0",
  secondary200: "#ffc6c1",
  secondary300: "#ffa2a3",
  secondary400: "#ff8b97",
  secondary500: "#ff6584",
  secondary600: "#db4974",
  secondary700: "#b73266",
  secondary800: "#932058",
  secondary900: "#7a134f",
  warning100: "#fff2cc",
  warning500: "#FF9800",
  warning900: "#7a3400",
  none: "none"
};
