






















































































import { Vue } from "vue-property-decorator";
import api from "../../utils/api";
import moment from "moment";
import VueJsonPretty from "vue-json-pretty";
import { OrganizationUnit } from "@legalplace/typeorm-entities";

const toLower = (text: string) => {
  return text.toString().toLowerCase();
};

const searchByName = (
  items: OrganizationUnit[],
  term: string
): OrganizationUnit[] => {
  if (term) {
    return items.filter((item: OrganizationUnit) =>
      toLower(item.name).includes(toLower(term))
    );
  }

  return items;
};

export default Vue.extend({
  name: "PartnerList",
  components: { VueJsonPretty },
  data: () => ({
    selected: {},
    search: "",
    searched: [],
    organizations: []
  }),
  methods: {
    getClass: ({ id }: OrganizationUnit) => ({
      "md-primary": id % 2 === 0,
      "md-accent": id % 2 !== 0
    }),
    moment: function(date: string) {
      return moment(new Date(date)).format("DD/MM/YYYY HH:mm:ss");
    },
    onSelect(item: OrganizationUnit) {
      this.selected = item;
    },
    searchOnTable() {
      // @ts-ignore
      this.searched = searchByName(this.organizations, this.search);
    }
  },
  async created() {
    const partners = await api.get("/partner/all");
    this.organizations = partners.data;
    this.searched = this.organizations;
  }
});
