<template>
  <div class="gitlab">
    <div v-for="(gitlabItem, i) in getGitlabItems" :key="i">
      <gitlab-item
        :name="gitlabItem.name"
        :repo="gitlabItem.repo"
      ></gitlab-item>
    </div>
  </div>
</template>

<script>
import GitlabItem from "./GitlabItem.vue";
import gitlabItems from "../../constants/gitlabConstants";

export default {
  name: "gitlab",
  components: {
    GitlabItem
  },
  computed: {
    getGitlabItems() {
      return gitlabItems;
    }
  }
};
</script>

<style lang="scss">
.gitlab {
  margin: auto;
  margin-top: 30px;
  width: 60%;
}
</style>
