import Swal from "sweetalert2";

export const loadingPopup = (title = "Loading", details = "") => {
  Swal.fire({
    title,
    html: details,
    onBeforeOpen: () => {
      Swal.showLoading();
    }
  });
};

export const closePopup = () => {
  Swal.close();
};
