



































































import { Vue } from "vue-property-decorator";
import api from "../../utils/api";

enum RequestStatus {
  NOT_LAUNCHED = "NOT_LAUNCHED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

export default Vue.extend({
  name: "CacheInvalidator",
  data: () => ({
    organizations: [],
    urlToInvalidate: null,
    distributionId: null,
    requestStatus: RequestStatus.NOT_LAUNCHED,
    loading: false
  }),
  methods: {
    async invalidateUrl() {
      this.loading = true;
      try {
        await api.post(`/cache/invalidate`, {
          url: this.urlToInvalidate,
          distributionId: this.distributionId
        });
        this.requestStatus = RequestStatus.SUCCESS;
      } catch (error) {
        console.error(error);
        this.requestStatus = RequestStatus.ERROR;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.requestStatus = RequestStatus.NOT_LAUNCHED;
      this.urlToInvalidate = null;
      this.distributionId = null;
    }
  },
  async created() {
    const partners = await api.get("/cache/withDistributionId");
    this.organizations = partners.data;
  }
});
