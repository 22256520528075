import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Login.vue";
import Gitlab from "../components/Gitlab/Gitlab.vue";
import PartnerList from "../components/partner/PartnerList.vue";
import AddPartner from "../components/partner/AddPartner.vue";
import EditPartner from "../components/partner/EditPartner.vue";
import CacheInvalidator from "../components/CacheInvalidator/CacheInvalidator.vue";

import Home from "../views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/partner",
    name: "partnerList",
    component: PartnerList,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/partner/add",
    name: "AddPartner",
    component: AddPartner,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/partner/edit/:id",
    name: "editPartner",
    component: EditPartner,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/gitlab",
    name: "gitlab",
    component: Gitlab,
    meta: { requiresAuth: true }
  },
  {
    path: "/cache-invalidator",
    name: "cacheInvalidator",
    component: CacheInvalidator,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next();
    } catch (e) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
  next();
});

export default router;
