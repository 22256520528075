
















































































































































































































































































































































































































import { Vue } from "vue-property-decorator";
import { closePopup, loadingPopup } from "../../utils/popup";
import api from "../../utils/api";
import { partnerDefaultStyle } from "../../constants/partner.constant";
import { getInitialTheme } from "../../utils/theme.helper";

export default Vue.extend({
  name: "editPartner",
  data: () => ({
    style: partnerDefaultStyle,
    partner: {} as any,
    logo: null,
    logoResult: null,
    homeLink: "https://legalplace.fr",
    logoStyle: {
      widthLg: "17.6rem",
      heightLg: "3.2rem",
      widthSm: "13.03rem",
      heightSm: "2.4rem"
    },
    favicon: null,
    faviconResult: null
  }),
  methods: {
    onFileChange(e: any, logo = true) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], logo);
    },
    createImage(file: any, logo = true) {
      const reader = new FileReader();
      if (logo) {
        this.logo = file;
      } else {
        this.favicon = file;
      }

      reader.onload = (e: any) => {
        if (logo) {
          this.logoResult = e.target.result;
        } else {
          this.faviconResult = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(logo = true) {
      if (logo) {
        this.logo = null;
        this.logoResult = null;
      } else {
        this.favicon = null;
        this.faviconResult = null;
      }
    },
    sendInfo: async function(logo = true) {
      const { widthLg, heightLg, widthSm, heightSm } = this.logoStyle;
      const id = this.$route.params.id;
      const fd = new FormData();
      const queryParams = `widthLg=${widthLg}&heightLg=${heightLg}&widthSm=${widthSm}&heightSm=${heightSm}&home=${this.homeLink}`;
      const file = logo ? this.logo : this.favicon;
      // @ts-ignore
      fd.append("image", file as string | Blob);
      await api.post(
        `upload/image/partner/${id}?${logo ? queryParams : "favicon=true"}`,
        fd,
        {}
      );
    },
    updatePartnerMetadata: async function() {
      try {
        loadingPopup();
        const { style } = this;
        const id = this.$route.params.id;
        const metadata = { ...this.partner.metadata };
        if (metadata?.theme?.params) {
          metadata.theme.params.style = {
            ...metadata.theme.params.style,
            ...style
          };
        }
        await api.put(`/partner/${id}`, { metadata });
      } catch (error) {
        console.error(`[Error]Update Style Partner ${error}`);
      } finally {
        closePopup();
      }
    },
    createWizardPartner: async function() {
      try {
        loadingPopup();
        const { partner } = this;
        await api.post(`/partner/wizard`, {
          domain: partner.domain,
          webflowDomain: partner.metadata?.webflowDomain
        });
      } catch (error) {
        console.error(`[Error]Update Style Partner ${error}`);
      } finally {
        closePopup();
      }
    }
  },
  async created() {
    const id = this.$route.params.id;
    const response = await api.get(`/partner/${id}`);
    const partner = response.data;
    this.partner = partner;
    const style = partner.metadata?.theme?.params?.style;
    this.style = getInitialTheme(partnerDefaultStyle, style);
    if (!this.partner.metadata) this.partner.metadata = {};
    const logo = partner.metadata?.theme?.params?.logo;
    if (logo) {
      this.homeLink = logo.home || this.homeLink;
      this.logoStyle = {
        widthSm: logo.logoSizes?.sm.width || this.logoStyle.widthSm,
        heightSm: logo.logoSizes?.sm.height || this.logoStyle.heightSm,
        widthLg: logo.logoSizes?.lg.width || this.logoStyle.widthLg,
        heightLg: logo.logoSizes?.lg.height || this.logoStyle.heightLg
      };
    }
  }
});
