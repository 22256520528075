<template>
  <div>
    <md-list>
      <md-list-item to="/partner">Partners</md-list-item>
      <md-list-item to="/gitlab">Gitlab</md-list-item>
      <md-list-item to="/cache-invalidator">Cache invalidator</md-list-item>
      <md-list-item :href="swaggerUrl">Swagger</md-list-item>
    </md-list>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "home",
  data() {
    return {
      swaggerUrl: `https://salameche${process.env.VUE_APP_DOMAIN}/api/api-swagger/index.html`
    };
  },
});
</script>

<style lang="scss" scoped>
.md-list {
  width: 320px;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, 0.12);
}
</style>
