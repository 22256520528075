







import { Component, Vue } from "vue-property-decorator";
import HeaderLp from "./components/HeaderLp.vue";
import { components } from "aws-amplify-vue";

@Component({
  components: {
    HeaderLp,
    ...components
  }
})
export default class App extends Vue {}
