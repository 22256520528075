


























import { Vue } from "vue-property-decorator";
import { closePopup, loadingPopup } from "../../utils/popup";
import api from "../../utils/api";

export default Vue.extend({
  name: "addPartner",
  data: () => ({
    domain: `test${process.env.VUE_APP_DOMAIN}`,
    webflowDomain: "",
    name: ""
  }),
  methods: {
    addDomain: async function() {
      try {
        loadingPopup();
        const { name, domain, webflowDomain } = this;
        const responseDB = await api.post(`/partner/db`, {
          name,
          domain,
          webflowDomain
        });
        const partnerDB = responseDB.data;
        await api.post(`/partner/`, { domain, webflowDomain });
        window.open(`https://${domain}/contrats/creation-sas/creer`);
        this.$router.push({ path: `/partner/edit/${partnerDB.id}` });
      } catch (error) {
        console.error(`[Error]Add Partner ${error}`);
      } finally {
        closePopup();
      }
    }
  }
});
