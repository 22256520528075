




















import { Vue } from "vue-property-decorator";
import api from "../../utils/api";

export default Vue.extend({
  name: "gitlabItem",
  data() {
    return {
      stage: String
    };
  },
  props: {
    repo: String,
    name: String
  },
  methods: {
    triggerCI: async function(event: any) {
      await api.get(`/gitlab/${this.repo}/${this.stage}`);
    }
  }
});
