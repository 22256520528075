import { partnerDefaultStyle } from "@/constants/partner.constant";

type CustomThemeType = typeof partnerDefaultStyle;

export const getInitialTheme = (
  defaultTheme: CustomThemeType,
  configTheme: CustomThemeType
) => ({
  ...defaultTheme,
  ...configTheme,
  borders: { ...defaultTheme.borders, ...configTheme.borders },
  boxShadows: { ...defaultTheme.boxShadows, ...configTheme.boxShadows },
  fontWeights: { ...defaultTheme.fontWeights, ...configTheme.fontWeights },
  backgroundColors: {
    ...defaultTheme.backgroundColors,
    ...configTheme.backgroundColors
  },
  typographyColors: {
    ...defaultTheme.typographyColors,
    ...configTheme.typographyColors
  },
  buttonColors: {
    ctaPrimary: {
      text: {
        ...defaultTheme.buttonColors.ctaPrimary.text,
        ...configTheme.buttonColors?.ctaPrimary?.text
      },
      background: {
        ...defaultTheme.buttonColors.ctaPrimary.background,
        ...configTheme.buttonColors?.ctaPrimary?.background
      }
    },
    ctaSecondary: {
      text: {
        ...defaultTheme.buttonColors.ctaSecondary.text,
        ...configTheme.buttonColors?.ctaSecondary?.text
      },
      background: {
        ...defaultTheme.buttonColors.ctaSecondary.background,
        ...configTheme.buttonColors?.ctaSecondary?.background
      }
    },
    ctaTertiary: {
      text: {
        ...defaultTheme.buttonColors.ctaTertiary.text,
        ...configTheme.buttonColors?.ctaTertiary?.text
      }
    },
    ctaPhone: {
      text: {
        ...defaultTheme.buttonColors.ctaPhone.text,
        ...configTheme.buttonColors?.ctaPhone?.text
      },
      background: {
        ...defaultTheme.buttonColors.ctaPhone.background,
        ...configTheme.buttonColors?.ctaPhone?.background
      }
    }
  }
});
